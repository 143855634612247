import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  fill: true,
  legend: {
    position: "right" as const,
  },
  plugins: {
    datalabels: {
      display: false,
    },
    legend: {
      position: "top" as const,
      align: "end" as const,
      labels: {
        usePointStyle: true,
        pointStyle: "circle",
        font: {
          size: 16,
        },
        boxHeight: 5,
        boxWidth: 5,
      },
    },
    tooltip: {
      position: "nearest" as const,
      caretSize: 0,
    },
  },
  scales: {
    x: {
      display: "auto" as const,
      grid: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        font: {
          size: 16,
          weight: "normal",
        },
        color: "#8A9995",
      },
    },
    y: {
      weight: 400,
      color: "#8A9995",
      grid: {
        borderDash: [6, 6],
        drawBorder: false,
      },
      ticks: {
        font: {
          size: 16,
          weight: "normal",
        },
        color: "#8A9995",
        padding: 5,
      },
      min: 0,
    },
  },
};
interface Props {
  data: any;
}

const LineChart = (props: Props) => {
  return <Line options={options} data={props.data} />;
};

export default LineChart;

import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  useRef,
} from "react";
import styled from "styled-components";
import Map from "./map";
import Hole from "./hole";
import data from "../../DB/newData.json";
import selectDown from "../../assets/icon/selectDown.svg";
import selectUp from "../../assets/icon/selectUp.svg";
import axios from "axios";
import { useInterval } from "usehooks-ts";

var count = 0;
interface Props {
  setHole: Dispatch<SetStateAction<number>>;
  date: string;
  setDate: Dispatch<SetStateAction<string>>;
  selectedDate: string;
  setSelectedDate: Dispatch<SetStateAction<string>>;
  eveDate: string;
  setEveDate: Dispatch<SetStateAction<string>>;
  hole: number;
  setViewMode: Dispatch<SetStateAction<string>>;
  viewMode: string;
  setAutoMode: Dispatch<SetStateAction<boolean>>;
}

const PanoramaSide = (props: Props) => {
  // const [viewMode, setViewMode] = useState<string>("map");
  const [dateModal, setDateModal] = useState<boolean>(false);
  // const [hole, setHole] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [scanCount, setScanCount] = useState<number[]>([]);
  const [intervalMode, setIntervalMode] = useState<boolean>(true);

  // useEffect(() => {
  //   axios.get(`${process.env.REACT_APP_API_URL}/dashboard`).then((data) => {
  //     const scanData = data.data.data;
  //     let tmpData: any[] = [];
  //     scanData.map((data: any) => tmpData.push(data.scanCount));
  //     setScanCount(tmpData);
  //     console.log(scanData);
  //   });
  // }, [props.selectedDate]);

  const dashboard = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/dashboard?Day=0`)
      .then((data) => {
        const resonseData = data.data.data;
        return resonseData;
      })
      .then((data: any) => {
        let tmpData: any[] = [];
        data.map((data: any) => tmpData.push(data.scanCount));
        setScanCount(tmpData);
        let tmpTotal = 0;
        data.map((data: any) => {
          tmpTotal = tmpTotal + data.scanCount;
        });
        setTotal(tmpTotal);
      });
  };

  useEffect(() => {
    // if (props.selectedDate === "") {
    //   setIntervalMode(true);
    // } else {
    //   setIntervalMode(false);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/dashboard?Day=${
          props.selectedDate === "all" ? `0` : `${props.selectedDate}`
        }`
      )
      .then((data) => {
        const resonseData = data.data.data;
        return resonseData;
      })
      .then((data: any) => {
        let tmpData: any[] = [];
        data.map((data: any) => tmpData.push(data.scanCount));
        setScanCount(tmpData);
        let tmpTotal = 0;
        data.map((data: any) => {
          tmpTotal = tmpTotal + data.scanCount;
        });
        setTotal(tmpTotal);
      });
    // }
  }, []);
  // useInterval(dashboard, intervalMode ? 1000 : null);
  // dashboard();

  // useEffect(() => {
  //   // axios
  //   //   .get(`${process.env.REACT_APP_API_URL}/dashboard${props.selectedDate !== "" ? `?Day=${props.selectedDate}` : ""}`)
  //   //   .then((data) => {
  //   //     const resonseData = data.data.data;
  //   //     return resonseData;
  //   //   })
  //   //   .then((data: any) => {
  //   //     let tmpData: any[] = [];
  //   //     data.map((data: any) => tmpData.push(data.scanCount));
  //   //     setScanCount(tmpData);
  //   //     let tmpTotal = 0;
  //   //     data.map((data: any) => {
  //   //       tmpTotal = tmpTotal + data.scanCount;
  //   //     });
  //   //     setTotal(tmpTotal);
  //   //   });
  //   useInterval(()=>dashboard(props.selectedDate), 1000)
  //   timeout2.current = window.setInterval(dashboard, 1000)

  // }, [props.date, props.selectedDate]);

  const date = new Date();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const dateHandler = (date: string) => {
    props.setHole(0);
    // if (date === "cur") {
    //   props.setSelectedDate("");
    //   props.setEveDate("");
    //   props.setAutoMode(true);
    // } else
    if (date === "all") {
      props.setSelectedDate("all");
      props.setEveDate("");
      props.setAutoMode(false);
    } else if (date === "first") {
      console.log("Click First");
      props.setSelectedDate("2022-10-21");
      props.setEveDate("2022-10-20");
      props.setAutoMode(false);
    } else if (date === "second") {
      props.setSelectedDate("2022-10-22");
      props.setEveDate("2022-10-21");
      props.setAutoMode(false);
    } else if (date === "third") {
      props.setSelectedDate("2022-10-23");
      props.setEveDate("2022-10-22");
      props.setAutoMode(false);
    }
  };

  const modifyHandler = (date: string) => {
    switch (props.selectedDate) {
      // case "":
      //   return "실시간";
      case "all":
        return "누계";
      case "2022-10-21":
        return "22.10.21";
      case "2022-10-22":
        return "22.10.22";
      case "2022-10-23":
        return "22.10.23";
    }
  };

  return (
    <Wrapper>
      <ContentWrapper>
        {props.viewMode === "map" && (
          <Map
            hole={props.hole}
            setHole={props.setHole}
            scanCount={scanCount}
          />
        )}
        {props.viewMode === "graph" && (
          <Hole
            hole={props.hole}
            setHole={props.setHole}
            scanCount={scanCount}
          />
        )}
        {/* {viewMode === "all" && <Holes />} */}
        <ViewGroup>
          <ModeButton
            mode={props.viewMode}
            onClick={() => props.setViewMode("map")}
          >
            지도보기
          </ModeButton>
          <ModeButton
            mode={props.viewMode}
            onClick={() => props.setViewMode("graph")}
          >
            그래프보기
          </ModeButton>
        </ViewGroup>
        <StatusGroup>
          <div>
            현재시각
            <span>
              {hours}:{minutes < 10 ? `0${minutes}` : minutes}
            </span>
          </div>
          <div>
            총 <span>{total}</span>번
          </div>
          <div onClick={() => setDateModal(!dateModal)}>
            <div>{modifyHandler(props.selectedDate)}</div>
            <img src={dateModal ? selectUp : selectDown} alt="date" />
            {dateModal && (
              <DateModal>
                {/* <div onClick={() => dateHandler("cur")}>실시간</div> */}
                <div onClick={() => dateHandler("all")}>누계</div>
                <div onClick={() => dateHandler("first")}>22.10.21</div>
                <div onClick={() => dateHandler("second")}>22.10.22</div>
                <div onClick={() => dateHandler("third")}>22.10.23</div>
              </DateModal>
            )}
          </div>
        </StatusGroup>
      </ContentWrapper>
    </Wrapper>
  );
};

export default PanoramaSide;

const Wrapper = styled.div`
  width: 100%;
  height: 775px;
  position: relative;
  border-radius: 12px;
  /* > div {
    position: absolute;
  } */
`;

const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.08);
  > div {
    /* width: 100%; */
    /* height: 100%; */
  }
`;

const ViewGroup = styled.div`
  display: flex;
  position: absolute;
  z-index: 200;
  top: 28px;
  left: 40px;
  @media screen and (max-width: 800px) {
    left: calc(100% - 220px);
    top: 80px;
  }
`;

const ModeButton = styled.div<{ mode: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 121px;
  height: 54px;
  cursor: pointer;
  font-family: "Apple SD Gothic Neo";
  font-style: normal;

  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.3px;

  :first-child {
    border-radius: 8px 0 0 8px;
    border: ${(props) =>
      props.mode === "map" ? "1px solid #00AA78" : "1px solid #D8DEDB"};
    background: ${(props) => (props.mode === "map" ? "#E6F8F2" : "#FFFFFF")};
    color: ${(props) => (props.mode === "map" ? "#00AA78" : "#70827D")};
    font-weight: ${(props) => (props.mode === "map" ? 700 : 400)};
  }
  :last-child {
    border-radius: 0 8px 8px 0;
    border: ${(props) =>
      props.mode === "map" ? "1px solid #D8DEDB" : "1px solid #00AA78"};
    background: ${(props) => (props.mode === "map" ? "#FFFFFF" : "#E6F8F2")};
    color: ${(props) => (props.mode === "map" ? "#70827D" : "#00AA78")};
    font-weight: ${(props) => (props.mode === "map" ? 400 : 700)};
  }
  @media screen and (max-width: 800px) {
    width: 60px;
    height: 30px;
  }
`;

const StatusGroup = styled.div`
  display: flex;
  position: absolute;
  z-index: 200;
  top: 28px;
  right: 40px;
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 4px;
    font-family: "Apple SD Gothic Neo";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 16px;
    letter-spacing: -0.325px;
    color: #303538;
    border-radius: 8px;
    > span {
      font-family: "Apple SD Gothic Neo";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: -0.325px;
      margin-left: 6px;
    }
    :first-child {
      width: 166px;
      margin-right: 12px;
    }
    :nth-child(2) {
      /* width: 94px; */
      margin-right: 12px;
      padding: 0 16px;
    }
    :last-child {
      position: relative;
      width: 158px;
      height: 54px;
      padding: 0 16px;
      background: #ffffff;
      border: 1px solid #d8dedb;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: "Apple SD Gothic Neo";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: -0.4px;
      color: #51665f;
      :hover {
        cursor: pointer;
      }
    }
  }
  @media screen and (max-width: 800px) {
    left: calc(100% - 220px);
    top: 80px;
  }
`;

const DateModal = styled.div`
  position: absolute;
  width: 188px;
  height: 218px;
  background: #ffffff;
  top: 50px;
  right: 0px;
  border: 1px solid #d8dedb;
  border-radius: 4px;
  > div {
    width: auto;
    padding-left: 16px;
    height: 44px;
    display: flex;
    align-items: center;
    font-family: "Apple SD Gothic Neo";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.4px;
    color: #555555;
    :hover {
      background-color: #f3fbf9;
      cursor: pointer;
    }
  }
`;

const Status = styled.div<{ mode: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 96px;
  height: 44px;
  background: ${(props) => (props.mode ? "#ffffff" : "rgba(13, 16, 26, 0.5)")};
  color: ${(props) => (props.mode ? "#333333" : "#ffffff")};
  :hover {
    background-color: ${(props) =>
      props.mode ? "#ffffff" : "rgba(13, 16, 26, 0.65)"};
  }
  :first-child {
    border-radius: 4px 0 0 4px;
  }
  :last-child {
    border-radius: 0 4px 4px 0;
  }
  @media screen and (max-width: 800px) {
    width: 60px;
    height: 30px;
  }
`;

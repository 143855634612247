import { Bar, getElementAtEvent } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Dispatch, SetStateAction, useRef } from "react";
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export interface Props {
  // holeHeadcount: any;
  hole: number;
  setHole: Dispatch<SetStateAction<number>>;
  data: any;
}

const BarChart = (props: Props) => {
  const chartRef = useRef<any>();

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
        formatter: (value: number) => {
          return value < 150 ? null : value;
        },
        tooltip: {
          position: "nearest" as const,
          caretSize: 0,
        },

        // anchor: "end" as const,
      },
    },
    scales: {
      x: {
        display: "auto" as const,
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          font: {
            size: 16,
          },
          color: (event: any) => {
            return event.index === props.hole ? "#00AA78" : "#70827D";
          },
          weight: (event: any) => {
            return event.index === props.hole ? 700 : 400;
          },
        },
      },
      y: {
        weight: 400,
        color: "#8A9995",
        grid: {
          borderDash: [6, 6],
          drawBorder: false,
        },
        ticks: {
          font: {
            size: 16,
            weight: "normal",
          },
          padding: 5,
        },
        min: 0,
      },
    },
  };

  const clickTest = (event: any) => {
    if (getElementAtEvent(chartRef.current, event).length === 0) return;
    const barIndex = getElementAtEvent(chartRef.current, event)[0].index;
    props.setHole(barIndex);
  };

  return <Bar ref={chartRef} options={options} data={props.data} onClick={clickTest} />;
};

export default BarChart;

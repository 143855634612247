import DataSide from "../components/data/index";
import PanoramaSide from "../components/panorama";
import styled from "styled-components";
import title from "../assets/icon/title.svg";
import wemix from "../assets/icon/wemix.svg";
import { useEffect, useState, useRef, RefCallback } from "react";
import data from "../DB/newData.json";
import Holes from "../components/holes";
import axios from "axios";
import { useInterval } from "usehooks-ts";

const todayDate = new Date();
const year = todayDate.getFullYear();
const month = todayDate.getMonth() + 1;
const day = todayDate.getDate();

var count = 0;

const Main = () => {
  const [hole, setHole] = useState<number>(0);
  const [date, setDate] = useState<string>(`${year}-${month}-${day}`);
  const [selectedDate, setSelectedDate] = useState<string>("all");
  const [eveDate, setEveDate] = useState<string>(``);

  const [viewMode, setViewMode] = useState<string>("map"); // graph
  const [chartMode1, setChartMode1] = useState<string>("gender"); // age
  const [chartMode2, setChartMode2] = useState<string>("with"); // brand
  const [autoMode, setAutoMode] = useState<boolean>(true);
  const timeout = useRef<number | undefined>(undefined);
  const viewChange = () => {
    count++;
    if (count % 4 === 1) {
      setViewMode("map");
      setChartMode1("age");
      setChartMode2("with");
    } else if (count % 4 === 2) {
      setViewMode("graph");
      setChartMode1("age");
      setChartMode2("brand");
    } else if (count % 4 === 3) {
      if (viewMode === "map") setViewMode("graph");
      setViewMode("map");
      setChartMode1("gender");
      setChartMode2("brand");
    } else {
      setViewMode("graph");
      setChartMode1("gender");
      setChartMode2("with");
    }
  };

  // const timerId = setInterval(viewChange, 10000);
  useInterval(viewChange, autoMode ? 10000 : null);
  // viewChange();

  // useEffect(() => {
  //   console.log(autoMode)
  //   if(autoMode) {
  //     timeout.current = window.setInterval(viewChange, 10000);
  //   } else {
  //     clearInterval(timeout.current)
  //   }
  //   return clearInterval(timeout.current)
  // }, [autoMode]);

  return (
    <>
      <Header>
        <HeaderWrapper>
          <img src={title} />
          <img src={wemix} />
          {/* <div>
            <div>KLPGA</div>
            <div>실시간 데이터 현황</div>
          </div> */}
        </HeaderWrapper>
      </Header>
      <Body>
        <BodyWrapper>
          <BodyDesc>
            <div>
              <span>KLPGA</span> 실시간 이벤트 현황
            </div>
            {/* <div>사람들이 주로 어디에 몰려있는지 확인ddd해 보세요.</div> */}
          </BodyDesc>
          <BodyContents>
            <LeftSide>
              <PanoramaSide
                setHole={setHole}
                hole={hole}
                date={date}
                setDate={setDate}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                eveDate={eveDate}
                setEveDate={setEveDate}
                viewMode={viewMode}
                setViewMode={setViewMode}
                setAutoMode={setAutoMode}
              />
            </LeftSide>
            <RightSide>
              <DataSide
                hole={hole}
                eveDate={eveDate}
                selectedDate={selectedDate}
                date={date}
                chartMode1={chartMode1}
                chartMode2={chartMode2}
                setChartMode1={setChartMode1}
                setChartMode2={setChartMode2}
              />
            </RightSide>
          </BodyContents>
          <Holes
            hole={hole}
            date={date}
            eveDate={eveDate}
            selectedDate={selectedDate}
          />
        </BodyWrapper>
      </Body>
    </>
  );
};

export default Main;

const Header = styled.div`
  display: flex;
  justify-content: center;
  height: 80px;
`;

const HeaderWrapper = styled.div`
  width: calc(100% - 160px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    display: flex;
    font-family: "Apple SD Gothic Neo";
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: #2e3a36;
    > :first-child {
      font-weight: 600;
      margin-right: 8px;
    }
  }

  @media screen and (min-width: 1920px) {
    width: 1760px;
    display: flex;
    align-items: center;
  }
`;

const Body = styled.div`
  width: 100vw;
  height: auto;
  display: flex;
  justify-content: center;
  background-color: #f7f8fd;
  padding-bottom: 70px;
  @media screen and (min-width: 1440px) {
    height: 100vh;
  }
`;

const BodyWrapper = styled.div`
  width: calc(100% - 40px);

  @media screen and (min-width: 800px) {
    width: calc(100% - 160px);
  }

  @media screen and (min-width: 1920px) {
    width: 1760px;
  }
`;

const BodyDesc = styled.div`
  height: 143px;
  border-bottom: 1px solid #e6e8eb;
  > div {
    font-family: "Apple SD Gothic Neo";
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 43px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;

    color: #2e3a36;
    padding-top: 45px;
    padding-bottom: 8px;

    > span {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 54px;
      letter-spacing: -0.02em;

      color: #2e3a36;
      margin-right: 8px;
    }
  }
  // > :last-child {
  //   font-family: "Apple SD Gothic Neo";
  //   font-style: normal;
  //   font-weight: 400;
  //   font-size: 14px;
  //   line-height: 21px;
  //   letter-spacing: -0.02em;

  //   color: #70827d;
  // }
`;

const BodyContents = styled.div`
  display: block;
  width: 100%;

  padding: 40px 0;
  @media screen and (min-width: 1440px) {
    display: flex;
  }
`;

const LeftSide = styled.div`
  width: 1040px;
  height: 775px;
  margin-right: 40px;
`;

const RightSide = styled.div`
  width: 680px;
  /* margin-top: 40px; */
`;

import { Dispatch, SetStateAction, useEffect, useState } from "react";
import background from "../../assets/image/background.svg";
import { ReactComponent as MarkerIcon } from "../../assets/icon/marker.svg";
import plus from "../../assets/icon/plus.svg";
import minus from "../../assets/icon/minus.svg";
import { TransformComponent, TransformWrapper } from "@pronestor/react-zoom-pan-pinch";
import styled from "styled-components";
import axios from "axios";

interface Props {
  hole: number;
  setHole: Dispatch<SetStateAction<number>>;
  scanCount: number[];
}

const Map = (props: Props) => {
  const forestPosition = [
    "f1-64-34.3",
    "f2-62-44.1",
    "f3-61-52.4",
    "f4-42.5-70",
    "f5-35.7-66",
    "f6-14.5-62",
    "f7-42.5-56.9",
    "f8-41-50.6",
    "f9-45-40.4",
  ];

  const lakePosition = [
    "l1-54-26.5",
    "l2-38-28",
    "l3-33-37.5",
    "l4-25.5-45.5",
    "l5-20-40.5",
    "l6-31-45.1",
    "l7-38-36",
    "l8-38-40.1",
    "l9-45-37",
  ];

  const holePosition = [
    "l1-66-10.8",
    "l2-45.5-13.8",
    "l3-38.8-30",
    "l4-31-44.7",
    "l5-23-35.8",
    "l6-37-44",
    "l7-45.5-27.7",
    "l8-46.5-35.3",
    "l9-54.5-35.5",
    "f1-78-24.5",
    "f2-74.5-42",
    "f3-73.5-56.8",
    "f4-51-88.1",
    "f5-43.5-80.8",
    "f6-17-73.8",
    "f7-51.5-64.8",
    "f8-49-53.5",
    "f9-54.8-26.8",
  ];

  return (
    <>
      {/* <Display> */}
      {/* <MapTitle>지도</MapTitle> */}
      {/* <ZoomGroup>
          <button onClick={() => zoomIn()}>
            <img src={plus} />
          </button>
          <button onClick={() => zoomOut()}>
            <img src={minus} />
          </button>
        </ZoomGroup> */}
      {/* </Display> */}

      <Background>
        {holePosition.map((data, index) => (
          <Marker chosen={index === props.hole} id={data} key={`hole${index}`} onClick={() => props.setHole(index)}>
            <MarkerIcon fill={index === props.hole ? "#FFFFFF" : "background: rgba(13, 16, 26, 0.7);"} />
            <div>
              <div>{index + 1}H</div>
              <div>
                <span>{props.scanCount[index]}</span>
                <span>명</span>
              </div>
            </div>
          </Marker>
        ))}
        <img src={`/background.svg`} alt="background" />
      </Background>
    </>
  );
};

export default Map;

const Background = styled.div`
  position: relative;
  width: 100%;
  height: 775px;
  /* // overflow: hidden; */
  border-radius: 12px;

  > div {
    /* overflow: hidden; */
  }
  > img {
    height: 100%;
    border-radius: 12px;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.08);
  }
`;

const Tools = styled.div`
  /* position: relative;
  bottom: 28px; */
`;

const Display = styled.div``;

const MapTitle = styled.div`
  position: absolute;
  top: 28px;
  left: 40px;
  font-family: "Apple SD Gothic Neo";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.6px;
  color: #ffffff;
  z-index: 200;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

const ZoomGroup = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 200;
  bottom: 28px;
  right: 40px;
  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: rgba(13, 16, 26, 0.5);
    width: 48px;
    height: 48px;
    :hover {
      background-color: rgba(13, 16, 26, 0.65);
    }
    :first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border-bottom: 1px solid rgba(230, 232, 235, 0.3);
    }
    :last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
`;

const Marker = styled.div<{ chosen: boolean }>`
  position: absolute;
  top: ${(props) => Number(props.id?.split("-")[1])}%;
  left: ${(props) => Number(props.id?.split("-")[2])}%;
  width: 52px;
  height: 52px;
  z-index: 200;
  cursor: pointer;
  > div {
    position: absolute;
    width: 52px;
    top: 9.5px;
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: center;
    > :first-child {
      font-family: "Apple SD Gothic Neo";
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: -0.3px;
      color: ${(props) => (props.chosen ? "#51665F" : "#f6f8fc")};
    }
    > :last-child {
      display: flex;
      justify-content: center;
      align-items: center;
      > :first-child {
        font-family: "Apple SD Gothic Neo";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.3px;
        color: ${(props) => (props.chosen ? "#05B682" : "#f6f8fc")};
        padding-right: 2px;
      }
      > :last-child {
        font-family: "Apple SD Gothic Neo";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.3px;
        color: ${(props) => (props.chosen ? "#8A9995" : "rgba(255, 255, 255, 0.5)")};
      }
    }
  }
`;

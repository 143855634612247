import { Dispatch, SetStateAction, useState } from "react";
import styled from "styled-components";
import BarChart from "../charts/bar";

interface Props {
  hole: number;
  setHole: Dispatch<SetStateAction<number>>;
  scanCount: number[];
}

const Hole = (props: Props) => {
  const [date, setDate] = useState<string>("first");

  const modifyDate = (date: string) => {
    switch (date) {
      case "first":
        return 20;
      case "second":
        return 21;
      case "third":
        return 22;
      case "fourth":
        return 23;
    }
  };

  const data = {
    labels: [
      "1H",
      "2H",
      "3H",
      "4H",
      "5H",
      "6H",
      "7H",
      "8H",
      "9H",
      "10H",
      "11H",
      "12H",
      "13H",
      "14H",
      "15H",
      "16H",
      "17H",
      "18H",
    ],
    datasets: [
      {
        label: "Headcount",
        data: props.scanCount,
        backgroundColor: (data: any) => {
          if (data.dataIndex === props.hole) return "#00AA78";
          else return "#D2F2E8";
        },
        borderRadius: 100,
        borderSkipped: false,
        borderWidth: 0,
        maxBarThickness: 20,
      },
    ],
  };

  return (
    <Wrapper>
      <ChartWrapper>
        <BarChart data={data} hole={props.hole} setHole={props.setHole} />
      </ChartWrapper>
    </Wrapper>
  );
};

export default Hole;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
`;

const DateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 128px 40px 40px 40px;
  background: #ffffff;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
`;

const ChartWrapper = styled.div`
  width: calc(100% - 80px);
  height: 527px;
`;

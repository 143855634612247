import styled from "styled-components";
import LineChart from "../charts/line";
import data from "../../DB/newData.json";
import { Chart as ChartJS, ScriptableContext, Filler } from "chart.js";
import { useEffect, useState } from "react";
import axios from "axios";
interface Props {
  hole: number;
  date: string;
  eveDate: string;
  selectedDate: string;
}

ChartJS.register(Filler);

const Holes = (props: Props) => {
  const [selectedData, setSelectedData] = useState<number[]>([]);
  const [eveData, setEveData] = useState<number[]>([]);
  const [selectedHoleData, setSelectedHoleData] = useState<number[]>([]);
  const [eveHoleData, setEveHoleData] = useState<number[]>([]);
  const [mode, setMode] = useState<string>("hole");

  // 홀별 종일 데이터
  useEffect(() => {
    console.log(props.selectedDate);
    let url = `${process.env.REACT_APP_API_URL}/time?hole=${props.hole + 1}&Day=${
      props.selectedDate !== "all" ? props.selectedDate : `2022-10-21`
    }`;
    console.log(url);
    axios.get(url).then((data) => {
      const responseData = data.data.data;
      let tmpData: number[] = [];
      responseData.map((data: { dateData: "string"; scanCount: number }) => {
        tmpData.push(data.scanCount);
      });
      setSelectedHoleData(tmpData);
    });
  }, [props.hole, props.selectedDate]);

  // 홀별 전일 데이터
  useEffect(() => {
    let tmpDay = props.selectedDate.split("-");
    if (tmpDay[2] === "21" || props.selectedDate === "all" || props.selectedDate === "0") {
      setEveHoleData([]);
      return;
    }
    let tmpDate = `${tmpDay[0]}-${tmpDay[1]}-${Number(tmpDay[2]) - 1}`;

    axios.get(`${process.env.REACT_APP_API_URL}/time?hole=${props.hole + 1}&Day=${tmpDate}`).then((data) => {
      const responseData = data.data.data;
      let tmpData: number[] = [];
      responseData.map((data: { dateData: "string"; scanCount: number }) => {
        tmpData.push(data.scanCount);
      });
      setEveHoleData(tmpData);
    });
  }, [props.hole, props.selectedDate]);

  // 전체 홀 종일   데이터
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/time?hole=0&Day=${
          props.selectedDate !== "all" ? props.selectedDate : `2022-10-21`
        }`
      )
      .then((data) => {
        const responseData = data.data.data;
        let tmpData: number[] = [];
        responseData.map((data: { dateData: "string"; scanCount: number }) => {
          tmpData.push(data.scanCount);
        });
        setSelectedData(tmpData);
      })
      .catch((err) => console.log(err, "day"));
  }, [props.hole, props.selectedDate]);

  // 전체 홀 전일 데이터
  useEffect(() => {
    let tmpDay = props.selectedDate.split("-");
    if (tmpDay[2] === "21" || props.selectedDate === "all" || props.selectedDate === "0") {
      setEveData([]);
      return;
    }
    let tmpDate = `${tmpDay[0]}-${tmpDay[1]}-${Number(tmpDay[2]) - 1}`;
    axios
      .get(`${process.env.REACT_APP_API_URL}/time?hole=0&Day=${tmpDate}`)
      .then((data) => {
        const responseData = data.data.data;
        let tmpData: number[] = [];
        responseData.map((data: { dateData: "string"; scanCount: number }) => {
          tmpData.push(data.scanCount);
        });
        setEveData(tmpData);
      })
      .catch((err) => console.log(err, "eve"));
  }, [props.hole, props.selectedDate]);

  const dailyData = {
    labels: ["6시", "7시", "8시", "9시", "10시", "11시", "12시", "13시", "14시", "15시", "16시", "17시"],
    datasets: [
      {
        label: "선택 홀 전날",
        data: eveData,
        fill: false,
        borderColor: "#A5BDFF",
        // backgroundColor: "#00AA78",
        pointRadius: 0,
        pointHoverRadius: 6,
        pointHoverBackgroundColor: "#A5BDFF",
        pointHoverBorderColor: "rgba(76, 123, 255, 0.3)",
        pointHoverBorderWidth: 12,
        borderDash: [5, 5],
      },
      {
        label: "선택 홀",
        data: selectedData,
        fill: true,
        backgroundColor: (context: ScriptableContext<"line">) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 400);
          gradient.addColorStop(0, "rgba(244, 149, 108, 0.2)");
          gradient.addColorStop(0.7, "rgba(255,255,255,0)");
          return gradient;
        },
        borderColor: "#F4956C",
        datalables: {
          display: false,
        },
        pointRadius: 0,
        pointHoverRadius: 6,
        pointHoverBackgroundColor: "#F4956C",
        pointHoverBorderColor: "rgba(244, 149, 108, 0.3)",
        pointHoverBorderWidth: 12,
      },
    ],
  };

  const holeData = {
    labels: ["6시", "7시", "8시", "9시", "10시", "11시", "12시", "13시", "14시", "15시", "16시", "17시"],
    datasets: [
      {
        label: "선택 홀 전날",
        data: eveHoleData,
        fill: false,
        borderColor: "#F4956C",
        backgroundColor: "rgba(244, 149, 108, 0.5)",
        pointRadius: 1,
        pointHoverRadius: 6,
        pointHoverBackgroundColor: "#F4956C",
        borderDash: [5, 5],
        pointHoverBorderColor: "rgba(244, 149, 108, 0.5)",
        pointHoverBorderWidth: 12,
      },
      {
        label: "선택 홀",
        data: selectedHoleData,
        fill: true,
        backgroundColor: (context: ScriptableContext<"line">) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 400);
          gradient.addColorStop(0, "rgba(0,170,120,1)");
          gradient.addColorStop(0.5, "rgba(255,255,255,0)");
          return gradient;
        },
        borderColor: "#00AA78",
        datalables: {
          display: false,
        },
        pointRadius: 0,
        // borderDash: [5, 5],
        pointHoverRadius: 6,
        pointHoverBackgroundColor: "#F4956C",
        pointHoverBorderColor: "rgba(0,170,120,1)",
        pointHoverBorderWidth: 12,
      },
    ],
  };

  const modifyDate = (date: string) => {
    if (date === "0") return "22.10.21 ~ 22.10.23";
    else if (date) return date;
    else return props.date;
  };

  return (
    <Wrapper>
      <Title>
        <Mode mode={mode}>
          <div onClick={() => setMode("hole")}>선택 홀 시간별 그래프</div>
          <div onClick={() => setMode("whole")}>전체 홀 시간별 그래프</div>
        </Mode>

        <div>
          {mode === "hole" && <div>{props.hole + 1}H</div>}
          <div> {modifyDate(props.selectedDate)}</div>
        </div>
      </Title>
      <LineChartContainer>
        <LineChart data={mode === "hole" ? holeData : dailyData} />
      </LineChartContainer>
    </Wrapper>
  );
};

export default Holes;

const Wrapper = styled.div`
  width: calc(100% - 80px);
  height: 291px;
  padding: 28px 40px;
  background: #ffffff;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: "Apple SD Gothic Neo";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.6px;
  color: #2e3a36;
  > :last-child {
    display: flex;
    font-family: "Apple SD Gothic Neo";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.4px;
    color: #70827d;
    > :first-child {
      margin-right: 16px;
    }
    > :last-child {
      margin-right: 16px;
    }
  }
`;

const Mode = styled.div<{ mode: string }>`
  display: flex;
  cursor: pointer;
  > :first-child {
    margin-right: 16px;
    color: ${(props) => (props.mode === "hole" ? "#2E3A36" : "#A0ACA9")};
  }
  > :last-child {
    color: ${(props) => (props.mode === "whole" ? "#2E3A36" : "#A0ACA9")};
  }
`;
const LineChartContainer = styled.div`
  /* width: 100%; */
  height: 261px;
`;

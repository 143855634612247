import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Doughnut } from "react-chartjs-2";

Chart.register(ArcElement, Tooltip, Legend, ChartDataLabels);

export const plugins = {
  legend: {},
};

interface Props {
  data: any;
  chartMode: string;
}

const DonutChart = (props: Props) => {
  const options = {
    responsive: true,
    cutout: "35%",
    layout: {
      autoPadding: false,
      // padding: {
      //   top: 50,
      // },
    },
    plugins: {
      datalabels: {
        display: true,
        formatter: (value: number, context: any) => {
          const total = context.dataset.data.reduce((sum: number, curValue: number) => sum + curValue);
          return value / total < 0.05 ? null : value;
        },
        color: "#ffffff",
        backgroundColor: "#00000085",
        labels: {
          title: {
            font: {
              weight: 500,
            },
          },
        },
        anchor: "center" as const,
        borderRadius: 4,
      },

      legend: {
        position: "right" as const,
        // maxHeight: 80,
        maxWidth: 100,
        fullSize: false,
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          font: {
            size: 16,
          },
          padding: 10,
          textAlign: "left" as const,
          boxHeight: 5,
        },
      },
    },
  };

  const options2 = {
    responsive: true,
    cutout: "35%",
    layout: {
      autoPadding: false,
      // padding: {
      //   top: 50,
      // },
    },
    plugins: {
      datalabels: {
        display: true,
        formatter: (value: number, context: any) => {
          const total = context.dataset.data.reduce((sum: number, curValue: number) => sum + curValue);
          return value / total < 0.05 ? null : value;
        },
        color: "#ffffff",
        backgroundColor: "#00000085",
        labels: {
          title: {
            font: {
              weight: 500,
            },
          },
        },
        anchor: "center" as const,
        borderRadius: 4,
      },

      legend: {
        position: "right" as const,
        // maxHeight: 80,
        maxWidth: 100,
        fullSize: false,
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          font: {
            size: 16,
          },
          padding: 10,
          textAlign: "left" as const,
          boxHeight: 5,
        },
      },
    },
  };

  const options3 = {
    responsive: true,
    cutout: "35%",
    layout: {
      autoPadding: false,
      // padding: {
      //   top: 50,
      // },
    },
    plugins: {
      datalabels: {
        display: true,
        formatter: (value: number, context: any) => {
          const total = context.dataset.data.reduce((sum: number, curValue: number) => sum + curValue);
          return value / total < 0.05 ? null : value;
        },
        color: "#ffffff",
        backgroundColor: "#00000085",
        labels: {
          title: {
            font: {
              weight: 500,
            },
          },
        },
        anchor: "center" as const,
        borderRadius: 4,
      },

      legend: {
        position: "bottom" as const,
        // maxHeight: 80,
        maxWidth: 150,
        fullSize: false,
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          font: {
            size: 16,
          },
          padding: 10,
          textAlign: "left" as const,
          boxHeight: 5,
          boxWidth: 5,
        },
      },
    },
  };

  return (
    <>
      {(props.chartMode === "gender" || props.chartMode === "with") && <Doughnut data={props.data} options={options} />}
      {props.chartMode === "age" && <Doughnut data={props.data} options={options2} />}
      {props.chartMode === "brand" && <Doughnut data={props.data} options={options3} />}
    </>
  );
};

export default DonutChart;

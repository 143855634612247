import { useEffect, useState, Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import DonutChart from "../charts/donut";
import expand from "../../assets/icon/expand.svg";
import fold from "../../assets/icon/fold.svg";
import LineChart from "../charts/line";
import data from "../../DB/newData.json";
import { Chart as ChartJS, ScriptableContext, Filler } from "chart.js";
import axios from "axios";
import playerData from "../../DB/playerData.json";
import { Line } from "rc-progress";

interface Props {
  hole: number;
  eveDate: string;
  selectedDate: string;
  date: string;
  setChartMode1: Dispatch<SetStateAction<string>>;
  chartMode1: string;
  setChartMode2: Dispatch<SetStateAction<string>>;
  chartMode2: string;
}

ChartJS.register(Filler);

const DataSide = (props: Props) => {
  // const [mode1, setMode1] = useState<string>("gender");
  // const [mode2, setMode2] = useState<string>("with");
  const [expandMode, setExpandMode] = useState<boolean>(false);
  const [[male, female], setGender] = useState<number[]>([0, 0]);
  const [[teenage, twenty, thirty, forty, fifty, sixty], setAge] = useState<
    number[]
  >([0, 0, 0, 0, 0, 0]);
  const [[friend, couple, family, withEtc], setWith] = useState<number[]>([
    0, 0, 0, 0,
  ]);
  const [[titleist, bridgeStone, taylorMade, brandEtc], setBrand] = useState<
    number[]
  >([0, 0, 0, 0, 0]);
  const [player, setPlayer] = useState<string[]>([]);
  const [playerName, setPlayerName] = useState<string[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);

  // useEffect(() => {
  //   var cnt = 0;
  //   const dynamicMode = () => {
  //     cnt++;
  //     if(cnt%4 === 0) {
  //       setMode1("gender");
  //       setMode2("with");
  //     } else if(cnt%4 === 1) {
  //       setMode1("age");
  //       setMode2("with");
  //     } else if(cnt%4 === 2) {
  //       setMode1("age");
  //       setMode2("brand");
  //     } else {
  //       setMode1("gender");
  //       setMode2("brand");
  //     }
  //   }

  //   const timerId = setInterval(dynamicMode, 10000);
  //   return () => clearInterval(timerId)
  // }, [])
  // 성별 데이터
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/gender/all`).then((data) => {
      const genderData = data.data.data;
      setGender([genderData.male, genderData.female]);
    });
  }, [props.chartMode1]);

  // 연령별 데이터
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/age/all`).then((data) => {
      const ageData = data.data.data;
      setAge([
        ageData.teenage,
        ageData.twenty,
        ageData.thirty,
        ageData.forty,
        ageData.fifty,
        ageData.sixty,
      ]);
    });
  }, [props.chartMode1]);

  // With 데이터
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/with/all`).then((data) => {
      const withData = data.data.data;
      setWith([
        withData.friend,
        withData.couple,
        withData.family,
        withData.etc,
      ]);
    });
  }, [props.chartMode2]);

  // 브랜드 데이터
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/brand/all`).then((data) => {
      const brandData = data.data.data;
      setBrand([
        brandData.titleist,
        brandData.bridgeStone,
        brandData.taylorMade,
        brandData.etc,
      ]);
    });
  }, [props.chartMode2]);

  // 선수 데이터
  useEffect(() => {
    // const playerData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/cheeringPlayer/all`)
      .then((data) => {
        let tmpData = [];
        const playerData = data.data.data;
        return playerData;
      })
      .then((playerData) => {
        let tmpPlayerName: string[] = [];
        let tmpPlayerCount: string[] = [];
        let tmpTotalCount: number = 0;
        Object.entries(playerData).map((data: any) => {
          tmpPlayerName.push(data[1].player);
          tmpPlayerCount.push(data[1].count);
          tmpTotalCount += Number(data[1].count);
        });

        setPlayerName(tmpPlayerName);
        setPlayer(tmpPlayerCount);
        setTotalCount(tmpTotalCount);
      });
    // };
    // playerData();
    // const timerId2 = setInterval(playerData, 100000);
    // return () => clearInterval(timerId2);
  }, []);

  const genderData = {
    labels: ["남자", "여자"],
    datasets: [
      {
        label: "성별",
        data: [male, female],
        backgroundColor: ["#4C7BFF", "#F15263"],
        borderColor: ["#4C7BFF", "#F15263"],
        borderWidth: 1,
        align: "end",
        anchor: "end",
      },
    ],
  };
  const ageData = {
    labels: ["10대", "20대", "30대", "40대", "50대", "60대~"],
    datasets: [
      {
        label: "연령",
        data: [teenage, twenty, thirty, forty, fifty, sixty],
        backgroundColor: [
          "#4C7BFF",
          "#05B682",
          "#F15263",
          "#FE8F5F",
          "#FFD74E",
          "#B04CFF",
        ],
        borderColor: [
          "#4C7BFF",
          "#05B682",
          "#F15263",
          "#FE8F5F",
          "#FFD74E",
          "#B04CFF",
        ],
        borderWidth: 1,
      },
    ],
  };
  const withData = {
    labels: ["친구", "연인", "가족", "기타"],
    datasets: [
      {
        label: "누구와",
        data: [friend, couple, family, withEtc],
        backgroundColor: [
          "#4C7BFF",
          "#05B682",
          "#F15263",
          "#FE8F5F",
          "#FFD74E",
        ],
        borderColor: ["#4C7BFF", "#05B682", "#F15263", "#FE8F5F", "#FFD74E"],
        borderWidth: 1,
      },
    ],
  };

  const brandData = {
    labels: ["타이틀리스트", "브릿지스톤", "테일러메이드", "기타"],
    datasets: [
      {
        label: "브랜드",
        data: [titleist, bridgeStone, taylorMade, brandEtc],
        backgroundColor: [
          "#4C7BFF",
          "#05B682",
          "#F15263",
          "#FE8F5F",
          "#FFD74E",
        ],
        borderColor: ["#4C7BFF", "#05B682", "#F15263", "#FE8F5F", "#FFD74E"],
        borderWidth: 1,
      },
    ],
  };

  const modifyDate = (date: string) => {
    if (date === "0") return "22.10.21 ~ 22.10.23";
    else if (date) return date;
    else return props.date;
  };

  const findCompanyHandler = (name: string) => {
    const company = Object.entries(playerData)?.filter(
      (data) => data[0] === name
    );
    if (company[0]) return <div>{company[0][1]}</div>;
    else return <div> </div>;
  };

  return (
    <DataContainer>
      <PlayerWrapper expandMode={expandMode}>
        <PlayerTitle>
          <div>인기투표</div>
          <div>실시간 랭킹 현황</div>
        </PlayerTitle>
        <PlayerContents>
          <TopRankerWrapper>
            {player[0] !== player[1] ? (
              <TopRanker2>
                <PlayerImg2>
                  <div>
                    <img src={`/player/${playerName[1]}.jpeg`} />
                  </div>
                  <div>2</div>
                </PlayerImg2>
                <PlayerDesc2>
                  <div>{playerName[1]}</div>
                  {findCompanyHandler(playerName[1])}
                  <div>{player[1]}표</div>
                </PlayerDesc2>
              </TopRanker2>
            ) : (
              <TopRanker1>
                <PlayerImg1>
                  <div>
                    <img src={`/player/${playerName[1]}.jpeg`} />
                  </div>
                  <div>1</div>
                </PlayerImg1>
                <PlayerDesc1>
                  <div>{playerName[1]}</div>
                  {findCompanyHandler(playerName[1])}
                  <div>{player[1]}표</div>
                </PlayerDesc1>
              </TopRanker1>
            )}
            <TopRanker1>
              <PlayerImg1>
                <div>
                  <img src={`/player/${playerName[0]}.jpeg`} />
                </div>
                <div>1</div>
              </PlayerImg1>
              <PlayerDesc1>
                <div>{playerName[0]}</div>
                {findCompanyHandler(playerName[0])}
                <div>{player[0]}표</div>
              </PlayerDesc1>
            </TopRanker1>
            {player[0] === player[1] && player[1] === player[2] && (
              <TopRanker1>
                <PlayerImg1>
                  <div>
                    <img src={`/player/${playerName[2]}.jpeg`} />
                  </div>
                  <div>1</div>
                </PlayerImg1>
                <PlayerDesc1>
                  <div>{playerName[2]}</div>
                  {findCompanyHandler(playerName[2])}
                  <div>{player[2]}표</div>
                </PlayerDesc1>
              </TopRanker1>
            )}
            {player[0] === player[1] && player[1] !== player[2] && (
              <TopRanker3>
                <PlayerImg3>
                  <div>
                    <img src={`/player/${playerName[2]}.jpeg`} />
                  </div>
                  <div>3</div>
                </PlayerImg3>
                <PlayerDesc3>
                  <div>{playerName[2]}</div>
                  {findCompanyHandler(playerName[2])}
                  <div>{player[2]}표</div>
                </PlayerDesc3>
              </TopRanker3>
            )}
            {player[0] !== player[1] && player[1] === player[2] && (
              <TopRanker2>
                <PlayerImg2>
                  <div>
                    <img src={`/player/${playerName[2]}.jpeg`} />
                  </div>
                  <div>2</div>
                </PlayerImg2>
                <PlayerDesc2>
                  <div>{playerName[2]}</div>
                  {findCompanyHandler(playerName[2])}
                  <div>{player[2]}표</div>
                </PlayerDesc2>
              </TopRanker2>
            )}
            {player[0] !== player[1] && player[1] !== player[2] && (
              <TopRanker3>
                <PlayerImg3>
                  <div>
                    <img src={`/player/${playerName[2]}.jpeg`} />
                  </div>
                  <div>3</div>
                </PlayerImg3>
                <PlayerDesc3>
                  <div>{playerName[2]}</div>
                  {findCompanyHandler(playerName[2])}
                  <div>{player[2]}표</div>
                </PlayerDesc3>
              </TopRanker3>
            )}
          </TopRankerWrapper>
          <RankerWrapper>
            {playerName.map((data, idx) => {
              if (idx > 2) {
                return (
                  <Ranker key={`${data}${idx}`}>
                    <div>
                      <div>{idx + 1}</div>
                      <div>{data === "" ? "없음" : data}</div>
                      {findCompanyHandler(playerName[idx])}
                    </div>
                    <div>
                      <Line
                        percent={Number(player[idx]) / totalCount}
                        strokeWidth={6}
                        strokeColor="#00AA78"
                        trailWidth={6}
                        trailColor="#E8EBEA"
                      />
                    </div>
                    <div>{player[idx]}표</div>
                  </Ranker>
                );
              }
            })}
          </RankerWrapper>
        </PlayerContents>
        <img
          src={!expandMode ? expand : fold}
          onClick={() => setExpandMode(!expandMode)}
        />
      </PlayerWrapper>
      <DonutChartWrapper>
        <DataWrapper>
          <Class1Title mode={props.chartMode1}>
            <div onClick={() => props.setChartMode1("gender")}>성별</div>
            <div onClick={() => props.setChartMode1("age")}>연령대</div>
          </Class1Title>
          <DonutChartContainer>
            <DonutChart
              data={props.chartMode1 === "gender" ? genderData : ageData}
              chartMode={props.chartMode1}
            />
          </DonutChartContainer>
        </DataWrapper>
        <DataWrapper>
          <Class2Title mode={props.chartMode2}>
            <div onClick={() => props.setChartMode2("with")}>동반자</div>
            <div onClick={() => props.setChartMode2("brand")}>
              브랜드 선호도
            </div>
          </Class2Title>
          <DonutChartContainer>
            <DonutChart
              data={props.chartMode2 === "with" ? withData : brandData}
              chartMode={props.chartMode2}
            />
          </DonutChartContainer>
        </DataWrapper>
      </DonutChartWrapper>
    </DataContainer>
  );
};

export default DataSide;

const DataContainer = styled.div`
  width: 680px;
  position: relative;
  > :first-child {
    margin-bottom: 40px;
  }
`;

const PlayerWrapper = styled.div<{ expandMode: boolean }>`
  position: absolute;
  width: calc(100% - 80px);
  background: #ffffff;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  height: ${(props) => (props.expandMode ? "719px" : "332px")};
  padding: 28px 40px;
  z-index: 100;
  overflow: hidden;
  > img {
    position: absolute;
    bottom: 28px;
    right: 40px;
    cursor: pointer;
  }
`;

const DataWrapper = styled.div`
  position: relative;
  width: 240px;
  top: 428px;
  background: #ffffff;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  height: 291px;
  padding: 28px 40px;
  :first-child {
    margin-right: 40px;
  }
`;

const DonutChartWrapper = styled.div`
  display: flex;
`;

const PlayerTitle = styled.div`
  display: flex;
  font-family: "Apple SD Gothic Neo";
  font-style: normal;
  justify-content: space-between;
  align-items: center;
  > :first-child {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.6px;
    color: #2e3a36;
    font-weight: 700;
  }
  > :last-child {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 136px;
    height: 40px;
    background: #2e3a36;
    border-radius: 100px;
    color: #ffffff;
    font-family: "Apple SD Gothic Neo";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.6px;
  }
`;

const PlayerContents = styled.div``;

const TopRankerWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 46px;
  justify-content: center;
`;
const TopRanker1 = styled.div`
  /* width: 120px; */
  margin-right: 40px;
  /* margin-top: 20px; */
`;

const PlayerImg1 = styled.div`
  position: relative;

  > :first-child {
    width: 114px;
    height: 114px;
    border: 3px solid #00aa78;
    border-radius: 50%;
    overflow: hidden;
    filter: drop-shadow(0px 0px 20px rgba(5, 182, 130, 0.7));
    > img {
      width: 120px;
      filter: drop-shadow(0px 0px 24px rgba(5, 182, 130, 0.56));
    }
  }
  > :last-child {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background: #f4af28;
    border-radius: 100px;
    top: 104px;
    left: 44px;
    color: #ffffff;
    font-family: "Apple SD Gothic Neo";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
`;

const PlayerDesc1 = styled.div`
  /* width: 100px; */
  margin-top: 28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > :first-child {
    font-family: "Apple SD Gothic Neo";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #2e3a36;
  }
  > :nth-child(2) {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #a0aca9;
  }
  > :nth-child(3) {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #00aa78;
    margin-top: 4px;
  }
`;

const TopRanker2 = styled.div`
  /* width: 100px; */
  margin-right: 40px;
  margin-top: 20px;
`;

const PlayerImg2 = styled.div`
  position: relative;
  > :first-child {
    width: 100px;
    height: 100px;
    border: 1px solid #e6e8eb;
    border-radius: 50%;
    overflow: hidden;
    > img {
      width: 100px;
    }
  }
  > :last-child {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background: #a9a9a9;
    border-radius: 100px;
    top: 84px;
    left: 34px;
    color: #ffffff;
    font-family: "Apple SD Gothic Neo";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
`;

const PlayerDesc2 = styled.div`
  /* width: 100px; */
  margin-top: 28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > :first-child {
    font-family: "Apple SD Gothic Neo";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #2e3a36;
  }
  > :nth-child(2) {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #a0aca9;
  }
  > :nth-child(3) {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #00aa78;
    margin-top: 4px;
  }
`;

const TopRanker3 = styled.div`
  /* width: 100px; */
  margin-top: 20px;
`;

const PlayerImg3 = styled.div`
  position: relative;
  > :first-child {
    width: 100px;
    height: 100px;
    border: 1px solid #e6e8eb;
    border-radius: 50%;
    overflow: hidden;
    > img {
      width: 100px;
    }
  }
  > :last-child {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background: #77664d;
    border-radius: 100px;
    top: 84px;
    left: 34px;
    color: #ffffff;
    font-family: "Apple SD Gothic Neo";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
`;

const PlayerDesc3 = styled.div`
  /* width: 100px; */
  margin-top: 28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > :first-child {
    font-family: "Apple SD Gothic Neo";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #2e3a36;
  }
  > :nth-child(2) {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #a0aca9;
  }
  > :nth-child(3) {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #00aa78;
    margin-top: 4px;
  }
`;

const RankerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 260px;
  overflow-y: scroll;
  margin-top: 40px;
`;

const Ranker = styled.div`
  display: flex;
  /* justify-content: space-between; */
  height: 51px;
  padding: 0 16px;
  border-bottom: 1px solid #e6e8eb;
  > :first-child {
    display: flex;
    width: 298px;
    height: 100%;
    align-items: center;
    margin-right: 16px;
    > :first-child {
      font-family: "Apple SD Gothic Neo";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #2e3a36;
      margin-right: 24px;
    }
    > :nth-child(2) {
      font-family: "Apple SD Gothic Neo";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #2e3a36;
      margin-right: 12px;
    }
    > :nth-child(3) {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 20px;
      color: #a0aca9;
    }
  }
  > :nth-child(2) {
    width: 168px;
    display: flex;
    align-items: center;
    margin-right: 16px;
  }
  > :last-child {
    display: flex;
    align-items: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #00aa78;
  }
`;

const Class1Title = styled.div<{ mode: string }>`
  display: flex;
  font-family: "Apple SD Gothic Neo";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.6px;
  > div {
    :first-child {
      color: ${(props) => (props.mode === "gender" ? "#2E3A36" : "#A0ACA9")};
      margin-right: 16px;
    }
    :nth-child(2) {
      color: ${(props) => (props.mode === "age" ? "#2E3A36" : "#A0ACA9")};
    }
    :hover {
      cursor: pointer;
    }
  }
`;

const Class2Title = styled.div<{ mode: string }>`
  display: flex;
  font-family: "Apple SD Gothic Neo";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.6px;
  > div {
    :first-child {
      color: ${(props) => (props.mode === "with" ? "#2E3A36" : "#A0ACA9")};
      margin-right: 16px;
    }
    :nth-child(2) {
      color: ${(props) => (props.mode === "brand" ? "#2E3A36" : "#A0ACA9")};
    }
    :hover {
      cursor: pointer;
    }
  }
`;

const DonutChartContainer = styled.div`
  /* width: 237px; */
  height: 224px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Apple SD Gothic Neo";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.325px;
  color: #303538;
  padding-top: 36px;
`;

const HourlyTitle = styled.div`
  display: flex;
  justify-content: space-between;
  > :last-child {
    font-family: "Apple SD Gothic Neo";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.4px;
    color: #70827d;
  }
`;

const LineChartContainer = styled.div`
  width: 100%;
  height: 100%;
`;
